import React, { useState, useContext } from "react";

import {
  Input,
  InputGroup,
  Button,
  DatePicker,
  Select,
  FormItem,
  FormContainer,
} from "components/ui";
//import { Input, Form, Formik } from "formik";
import OrganizerContext from "views/admin/OrganizerContext";
import UserListTable from "./UserListTable";
const initState = {
  fullname: "",
  mobile1: "",
  mobile2: "",
  mobile3: "",
  address: "",
  loftname: "",
  geo_h_latitude: "",
  geo_v_longitude: "",
  radius: "",
  username: "",
  passwd: "",
};

const Clubuser = () => {
  const [formData, setFormData] = useState(initState);
  const { doSaveNewUser } = useContext(OrganizerContext);
  const onInputTextChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmit = () => {
    doSaveNewUser(formData)
    setFormData(initState)

  };
  return (
    <FormContainer>
      {/* {JSON.stringify(formData)} */}
      <div className="md:grid grid-cols-3 gap-4">
        <FormItem
          // className=""
          label="fullname"
        >
          <Input
            type="text"
            autoComplete="off"
            name="fullname"
            value={formData.fullname}
            placeholder="fullname"
            component={Input}
            onChange={onInputTextChange}
          />
        </FormItem>
        <FormItem
          // className="w-full md:w-3/4"
          label="mobile1"
        >
          <Input
            type="text"
            autoComplete="off"
            name="mobile1"
            value={formData.mobile1}
            placeholder="mobile1"
            component={Input}
            onChange={onInputTextChange}
          />
        </FormItem>
        <FormItem label="address">
          <Input
            type="text"
            autoComplete="off"
            name="address"
            value={formData.address}
            placeholder="address"
            component={Input}
            onChange={onInputTextChange}
          />
        </FormItem>
      </div>
      <div className="md:grid grid-cols-2 gap-4"></div>
      <div className="md:grid grid-cols-2 gap-4"></div>

      <div className="md:grid grid-cols-3 gap-4">
        <FormItem label="username">
          <Input
            type="text"
            autoComplete="off"
            name="username"
            value={formData.username}
            placeholder="username"
            component={Input}
            onChange={onInputTextChange}
          />
        </FormItem>
        <FormItem label="passwd">
          <Input
            type="text"
            autoComplete="off"
            name="passwd"
            value={formData.passwd}
            placeholder="passwd"
            component={Input}
            onChange={onInputTextChange}
          />
        </FormItem>
        <FormItem label="usertype">
          <Input
            type="text"
            autoComplete="off"
            name="usertype"
            value={formData.usertype}
            placeholder="club|player|admin|root"
            component={Input}
            onChange={onInputTextChange}
          />
        </FormItem>
      </div>

      <div className="flex justify-end gap-2">
        <Button
          //loading={isSubmitting}
          variant="solid"
          type="submit"
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
      <h6>USER LIST</h6>
      <div className="mt-4">
        <UserListTable />
      </div>
    </FormContainer>
  );
};

export default Clubuser;
