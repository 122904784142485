import { createContext, useState, useEffect, useReducer } from "react";
import useApi, { API_URL, HTTP_METHODS, HTTP_STATUS } from "hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import RaiseToast from "hooks/RaiseToast";
const OrganizerContext = createContext(null);

const ACTION = {
  GET_ORGANIZER: "GET_ORGANIZER",
  POST_ORGANIZER: "POST_ORGANIZER",
  SET_SINGLE_ORGANIZER: "SET_SINGLE_ORGANIZER",
  SET_SINGLE_USER: "SET_SINGLE_USER",
  GET_USER_LIST: "GET_USER_LIST",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "GET_ORGANIZER": {
      return {
        ...state,
        Organizers: action.payload,
      };
    }
    case "SET_SINGLE_ORGANIZER": {
      return {
        ...state,
        SingleOrganizer: action.payload,
      };
    }
    case "GET_USER_LIST": {
      return {
        ...state,
        userlist: action.payload,
      };
    }
    case "SET_SINGLE_USER": {
      return {
        ...state,
        singleuser: action.payload,
      };
    }

    case "POST_ORGANIZER":
    default: {
      return {
        ...state,
      };
    }
  }
};

export const OrganizerContextProvider = ({ children }) => {
  const [switchMapViewerDialog, setswitchMapViewerDialog] = useState(false);
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [formEditData, setFormEditData] = useState([]);
  const [formEditUser, setformEditUser] = useState([]);
  const [state, dispatch] = useReducer(reducer, {
    Organizers: [],
    SingleOrganizer: [],
    userlist: [],
    singleuser: [],
  });

 
  const doGetUser = async () => {
    const { sendRequest } = useApi(API_URL.API_REGISTRATION);

    const res = await sendRequest({
      url: "userauth",
      method: HTTP_METHODS.GET,
    });

    const { status, data } = res;

    if (status === HTTP_STATUS.OK) {
      dispatch({ type: ACTION.GET_USER_LIST, payload: data });
    }
  };

  const doUpdateUser = async () => {
    const { sendRequest } = useApi(API_URL.API_REGISTRATION);

    const res = await sendRequest({
      url: "login/edit",
      method: HTTP_METHODS.POST,
      data: formEditUser,
    });

    const { status, data } = res;

    if (status === HTTP_STATUS.OK) {
      doGetUser();
      toast("Update complete!", {
        theme: "dark",
        autoClose: 2000,
      });
    }
  };

  const doSaveNewUser = async (payload) => {
    const { sendRequest } = useApi(API_URL.API_REGISTRATION);

    const res = await sendRequest({
      url: "userauth/regs",
      method: HTTP_METHODS.POST,
      data: payload,
    });

    const { status, data } = res;

    if (status === HTTP_STATUS.OK) {
      doGetUser();
      toast("User saving complete!", {
        theme: "dark",
        autoClose: 2000,
      });
    }
  };

  const doGetOrganizer = async () => {
    const { sendRequest } = useApi(API_URL.API_CLUB);

    const res = await sendRequest({
      url: "club",
      method: HTTP_METHODS.GET,
    });

    const { status, data } = res;

    if (status === HTTP_STATUS.OK) {
      dispatch({ type: ACTION.GET_ORGANIZER, payload: data });
    }
  
  };

  const doSubmitForm = async (formData) => {
    const { sendRequest } = useApi(API_URL.API_CLUB);

    const res = await sendRequest({
      url: "club/create",
      method: HTTP_METHODS.POST,
      data: formData,
    });

    const { status, data } = res;
    if (status === HTTP_STATUS.OK) {
      toast("Saving complete!", {
        theme: "dark",
        autoClose: 2000,
      });
    }
  };

  const doUpdateForm = async (formData) => {
    const { sendRequest } = useApi(API_URL.API_CLUB);

    const res = await sendRequest({
      url: "club/edit",
      method: HTTP_METHODS.PUT,
      data: formData,
    });

    const { status, data } = res;
    if (status === HTTP_STATUS.OK) {
      // alert("Done");
      doGetOrganizer();
      toast("Update complete!", {
        theme: "dark",
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    doGetOrganizer();
    doGetUser();
  }, []);

  return (
    <>
      <RaiseToast />
      <OrganizerContext.Provider
        value={{
          doGetOrganizer,
          state,
          doSubmitForm,
          dispatch,
          switchMapViewerDialog,
          setswitchMapViewerDialog,
          doUpdateForm,
          formEditData,
          setFormEditData,
          doGetUser,
          doSaveNewUser,
          formEditUser,
          setformEditUser,
          drawerOpen,
          setdrawerOpen,
          doUpdateUser,
        }}
      >
        {children}
      </OrganizerContext.Provider>
    </>
  );
};

export default OrganizerContext;
