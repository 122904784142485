import { render } from "react-dom";
import { useContext } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Circle,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { useState } from "react";
import OrganizerContext from "views/admin/OrganizerContext";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});




const TTMap_viewer = () => {
  
  
 const {state } = useContext(OrganizerContext)
 
 const position = [state.SingleOrganizer.geo_h_latitude, state.SingleOrganizer.geo_v_longitude];

  return (
    <MapContainer
      center={position}
      zoom={12}
      scrollWheelZoom={false}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#fff",
        marginTop: "80px",
        marginBottom: "90px",
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Circle center={position} radius={state.SingleOrganizer.radius} />
      {/* <CircleMarker center={position} radius={38} /> */}
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>

     
    </MapContainer>
  );
};

export default TTMap_viewer;
