import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setAuthToken,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { useEffect, useState, useContext } from "react";
import TTGlobalContext from "views/TTGlobalContext";

function useAuth() {
  // const [signedIn, setsignedIn] = useState(false);
  const { doLogOut } = useContext(TTGlobalContext);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (val) => {
    await dispatch(onSignInSuccess(val));
  };

  //   const signUp = async (values) => {
  //     try {
  //       const resp = await apiSignUp(values);
  //       if (resp.data) {
  //         const { token } = resp.data;
  //         dispatch(onSignInSuccess(token));
  //         if (resp.data.user) {
  //           dispatch(
  //             setUser(
  //               resp.data.user || {
  //                 avatar: "",
  //                 userName: "Anonymous",
  //                 authority: ["USER"],
  //                 email: "",
  //               }
  //             )
  //           );
  //         }
  //         const redirectUrl = query.get(REDIRECT_URL_KEY);
  //         navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
  //         return {
  //           status: "success",
  //           message: "",
  //         };
  //       }
  //     } catch (errors) {
  //       return {
  //         status: "failed",
  //         message: errors?.response?.data?.message || errors.toString(),
  //       };
  //     }
  //   };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    doLogOut()
    // handleSignOut();
  };

  return {
    //authenticated: token && signedIn, //mobin
    authenticated: signedIn, //mobin
    signIn,
    // signUp,
    signOut,
  };
}

export default useAuth;
