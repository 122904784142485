import React, { useState, useContext } from "react";
import {
  Input,
  Avatar,
  Upload,
  Button,
  Select,
  Switcher,
  Notification,
  toast,
  FormContainer,
  FormItem,
} from "components/ui";
import FormDesription from "./FormDesription";
import FormRow from "./FormRow";
import { Field, Form, Formik } from "formik";
import { components } from "react-select";
import {
  HiLockOpen,
  HiUser,
  HiOutlineBriefcase,
  HiDeviceMobile,
  HiCheck,
  HiHome,
} from "react-icons/hi";
import * as Yup from "yup";
import TTGlobalContext from "views/TTGlobalContext";
const { Control } = components;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(12, "Too Long!")
    .required("User Name Required"),
  email: Yup.string().email("Invalid email").required("Email Required"),
  title: Yup.string(),
  avatar: Yup.string(),
  lang: Yup.string(),
  timeZone: Yup.string(),
  syncData: Yup.bool(),
});

// const langOptions = [
//   { value: "en", label: "English (US)", imgPath: "/img/countries/us.png" },
//   { value: "ch", label: "中文", imgPath: "/img/countries/cn.png" },
//   { value: "jp", label: "日本语", imgPath: "/img/countries/jp.png" },
//   { value: "fr", label: "French", imgPath: "/img/countries/fr.png" },
// ];

// const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {
//   return (
//     <div
//       className={`flex items-center justify-between p-2 ${
//         isSelected
//           ? "bg-gray-100 dark:bg-gray-500"
//           : "hover:bg-gray-50 dark:hover:bg-gray-600"
//       }`}
//       {...innerProps}
//     >
//       <div className="flex items-center">
//         <Avatar shape="circle" size={20} src={data.imgPath} />
//         <span className="ml-2 rtl:mr-2">{label}</span>
//       </div>
//       {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
//     </div>
//   );
// };

// const CustomControl = ({ children, ...props }) => {
//   const selected = props.getValue()[0];
//   return (
//     <Control {...props}>
//       {selected && (
//         <Avatar
//           className="ltr:ml-4 rtl:mr-4"
//           shape="circle"
//           size={18}
//           src={selected.imgPath}
//         />
//       )}
//       {children}
//     </Control>
//   );
// };

const Profile = ({ data }) => {
  const [formData, setFormData] = useState(data);
  const { doUpdateMyProfile } = useContext(TTGlobalContext);
  const onSetFormFile = (form, field, file) => {
    form.setFieldValue(field.name, URL.createObjectURL(file[0]));
  };

  const onFormSubmit = (values, setSubmitting) => {
    doUpdateMyProfile(values);
    // toast.push(<Notification title={"Profile updated"} type="success" />, {
    //   placement: "top-center",
    // });
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      //validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        //   onFormSubmit(values, setSubmitting);
        //alert("Test")
        setSubmitting(true);
        setTimeout(() => {
          onFormSubmit(values, setSubmitting);
        }, 1000);
      }}
    >
      {({ values, touched, errors, isSubmitting, resetForm }) => {
        const validatorProps = { touched, errors };
        return (
          <Form>
            {/* {JSON.stringify(formData)} */}
            <FormContainer>
              <FormDesription
                title={`MY ACCOUNT [${data.userid}]`}
                // desc="Basic info, like your name and address that will displayed in public"
              />
            
            <div className="grid row">
            <div className="grid grid-cols-3 gap-4">
                
                <FormItem name="fullname" label="Name" {...validatorProps}>
                  <Field
                    type="text"
                    autoComplete="off"
                    name="fullname"
                    placeholder="Name"
                    component={Input}
                    //  value={formData.fullname}
                    // onChange={_onChange}
                    prefix={<HiUser className="text-xl" />}
                  />
                </FormItem>
                <FormItem name="address" label="Address" {...validatorProps}>
                  <Field
                    type="text"
                    autoComplete="off"
                    name="address"
                    placeholder="Address"
                    component={Input}
                    prefix={<HiHome className="text-xl" />}
                  />
                </FormItem>

                <FormItem
                  name="loftname"
                  label="Loft"
                  {...validatorProps}
                  border={false}
                >
                  <Field
                    type="text"
                    autoComplete="off"
                    name="loftname"
                    placeholder="Loft"
                    component={Input}
                    prefix={<HiHome className="text-xl" />}
                  />
                </FormItem>
        
             </div></div> 
           
                <FormDesription
                  className="mt-8"
                  title="Contact Number"
                  //desc="Your personalized preference displayed in your account"
                />
            <div className="grid row">
            <div className="grid grid-cols-3 gap-4">
           
                <FormItem
                  name="mobile1"
                  label="Mobile 1"
                  {...validatorProps}
                  border={false}
                >
                  <Field
                    type="text"
                    autoComplete="off"
                    name="mobile1"
                    placeholder="Mobile 1"
                    component={Input}
                    prefix={<HiDeviceMobile className="text-xl" />}
                  />
                </FormItem>
                <FormItem
                  name="mobile2"
                  label="Mobile 2"
                  {...validatorProps}
                  border={false}
                >
                  <Field
                    type="text"
                    autoComplete="off"
                    name="mobile2"
                    placeholder="Mobile 2"
                    component={Input}
                    prefix={<HiDeviceMobile className="text-xl" />}
                  />
                </FormItem>
                <FormItem
                  name="mobile3"
                  label="Mobile 3"
                  {...validatorProps}
                  border={false}
                >
                  <Field
                    type="text"
                    autoComplete="off"
                    name="mobile3"
                    placeholder="Mobile 3"
                    component={Input}
                    prefix={<HiDeviceMobile className="text-xl" />}
                  />
                </FormItem>
                </div></div> 
              <FormDesription
                className="mt-8"
                title="SECURITY"
                //desc="Your personalized preference displayed in your account"
              />
                <div className="grid row">
            <div className="grid grid-cols-2 gap-4">
           
              <FormItem
                name="username"
                label="Username"
                {...validatorProps}
                border={false}
              >
                <Field
                  type="text"
                  autoComplete="off"
                  name="username"
                  placeholder="Username"
                  component={Input}
                  prefix={<HiUser className="text-xl" />}
                />
              </FormItem>
              <FormItem
                name="passwd"
                label="Password"
                {...validatorProps}
                border={false}
              >
                <Field
                  type="text"
                  autoComplete="off"
                  name="passwd"
                  placeholder="Password"
                  component={Input}
                  prefix={<HiLockOpen className="text-xl" />}
                />
              </FormItem>
              </div></div> 
             
              <div className="mt-4 ltr:text-right">
                {/* <Button
                  className="ltr:mr-2 rtl:ml-2"
                  type="button"
                  onClick={resetForm}
                >
                  Reset
                </Button> */}
                <Button variant="solid" loading={isSubmitting} type="submit">
                  {isSubmitting ? "Updating" : "Update"}
                </Button>
              </div> 
            </FormContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Profile;
