import { createContext, useState, useEffect, useReducer } from "react";
import useApi, { API_URL, HTTP_METHODS, HTTP_STATUS } from "hooks/useApi";
import { ToastContainer, toast } from "react-toastify";
import RaiseToast from "hooks/RaiseToast";
import { globalLocales } from "@fullcalendar/react";
import Profile from "./account/Settings/components/Profile";
import { setAuthToken } from "store/auth/sessionSlice";
import { useSelector, useDispatch } from "react-redux";
import { sonnydecrypt, isTokenExpired } from "commonhelper";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setMyClub,
} from "store/auth/sessionSlice";

import Clubuser from "./admin/Users/components/Clubuser";
const TTGlobalContext = createContext(null);

const ACTION = {
  GET_USER_LOGIN: "GET_USER_LOGIN",
  POST_ORGANIZER: "POST_ORGANIZER",
  SET_SINGLE_ORGANIZER: "SET_SINGLE_ORGANIZER",
  GET_USER_LIST: "GET_USER_LIST",
  SET_AUTH: "SET_AUTH",
  SET_CLUB: "SET_CLUB",
  SET_TOKEN: "SET_TOKEN",
  SET_DASHBOARDPARTICIPANTS: "SET_DASHBOARDPARTICIPANTS",
};

const reducer = (globalstate, action) => {
  switch (action.type) {
    case "GET_USER_LOGIN": {
      return {
        ...globalstate,
        loginuser: action.payload,
      };
    }
    case "SET_SINGLE_ORGANIZER": {
      return {
        ...globalstate,
        SingleOrganizer: action.payload,
      };
    }
    case "SET_CLUB": {
      return {
        ...globalstate,
        myclub: action.payload,
      };
    }
    case "SET_AUTH": {
      return {
        ...globalstate,
        setsignedIn: action.payload,
      };
    }

    case "GET_USER_LIST": {
      return {
        ...globalstate,
        userlist: action.payload,
      };
    }

    case "SET_TOKEN": {
      return {
        ...globalstate,
        token: action.payload,
      };
    }

    case "SET_DASHBOARDPARTICIPANTS": {
      return {
        ...globalstate,
        eventparticipants: action.payload,
      };
    }

    case "POST_ORGANIZER":
    default: {
      return {
        ...globalstate,
      };
    }
  }
};

export const TTGlobalContextProvider = ({ children }) => {
  const dispatch_persist = useDispatch();
  const tox = useSelector((state) => state.auth.session.authtoken);
  const cred = useSelector((state) => state.auth.session.token);
  const [globalstate, dispatch] = useReducer(reducer, {
    loginuser: [],
    myclub: [],
    SingleOrganizer: [],
    userlist: [],
    setsignedIn: false,
    token: "",
    eventparticipants: [],
  });

  //user related
  //once logged in
  /*
1. store the Profile
2. store the Club

*/

  const doLogOut = async () => {
    if (isTokenExpired(tox)) {
      //alert("expired")
      doGetToken();
    }
    let payload = {};
    payload.username = cred[0].username;
    const { sendRequest } = useApi(API_URL.API_REGISTRATION);
    const res = await sendRequest({
      url: "refreshtoken/deletetoken",
      method: HTTP_METHODS.POST,
      data: payload,
      headers: {
        Authorization: `Basic ${sonnydecrypt(tox)}`,
      },
    });
    const { status, data } = res;

    if (status === 404) {
      alert("unable to logout");
    }
    if (status === HTTP_STATUS.OK) {
      dispatch_persist(onSignOutSuccess());
    }
    //return data;
  };

  const doGetToken = async () => {
    let payload = {};
    payload.username = cred[0].username;
    const { sendRequest } = useApi(API_URL.API_REGISTRATION);
    let userdataset = {};
    const res = await sendRequest({
      url: "refreshtoken/gettoken",
      method: HTTP_METHODS.POST,
      data: payload,
    });

    const { status, data } = res;

    if (status === 404) {
      // alert("hello");
      dispatch_persist(onSignOutSuccess());
    }
    if (status === HTTP_STATUS.OK) {
      if (data) {
        dispatch_persist(setAuthToken(data.data));
      }
    }
    return data;
  };

  const doLogin = async (payload) => {
    const { sendRequest } = useApi(API_URL.API_REGISTRATION);
    let userdataset = {};
    const res = await sendRequest({
      url: "login/login",
      method: HTTP_METHODS.POST,
      data: payload,
    });

    const { status, data } = res;

    if (status === HTTP_STATUS.OK) {
      if (data) {
        const { token, jdata } = data;

        dispatch({ type: ACTION.GET_USER_LOGIN, payload: jdata });
        dispatch({ type: ACTION.SET_AUTH, payload: true });
        dispatch_persist(setAuthToken(token));
      }
    }
    return data;
  };

  const doGenerateRandom = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const doUpdateMyProfile = async (payload) => {
    const { sendRequest } = useApi(API_URL.API_REGISTRATION);
    const res = await sendRequest({
      url: "login/editprofile",
      method: HTTP_METHODS.POST,
      data: payload,
    });

    const { status, data } = res;
    //  alert("fire", data);
    if (status === HTTP_STATUS.OK) {
      toast("Update complete!", {
        theme: "dark",
        autoClose: 1000,
      });
    }
  };

  const doGetClub = async (user) => {
    // const dispatchSession = useDispatch();
    const { sendRequest } = useApi(API_URL.API_CLUB);

    let _myclub;
    const res = await sendRequest({
      url: "club",
      method: HTTP_METHODS.GET,
    });

    const { status, data } = res;
    //  alert("fire", data);

    if (status === HTTP_STATUS.OK) {
      if (data.length > 0) {
        //check admin 1
        _myclub = data.filter((a) => {
          return a.admin1 === user || a.admin2 === user || a.admin3 === user;
        });
        dispatch({ type: ACTION.SET_CLUB, payload: _myclub });
      }
    }
  };

  const doGetParticipantsCount = async (params) => {
    // const dispatchSession = useDispatch();
    const { sendRequest } = useApi(API_URL.API_RACE_EVENT);

    const res = await sendRequest({
      url: "dashboard/getparticipantscount",
      method: HTTP_METHODS.POST,
      data: params,
    });

    const { status, data } = res;
    //  alert("fire", data);

    if (status === HTTP_STATUS.OK) {
      dispatch({ type: ACTION.SET_DASHBOARDPARTICIPANTS, payload: data });
    }
  };

  return (
    <>
      <RaiseToast />
      <TTGlobalContext.Provider
        value={{
          globalstate,
          doLogin,
          doGetClub,
          doUpdateMyProfile,
          doGenerateRandom,
          doGetToken,
          doLogOut,
          doGetParticipantsCount,
        }}
      >
        {children}
      </TTGlobalContext.Provider>
    </>
  );
};

export default TTGlobalContext;
