import React, { useEffect, useCallback, useMemo, useContext } from "react";
import { Avatar, Badge } from "components/ui";
import acronym from "utils/acronym";
import { DataTable } from "components/shared";
import { useDispatch, useSelector } from "react-redux";
//import { getCustomers, setTableData } from "../store/dataSlice";
import { setSelectedCustomer, setDrawerOpen } from "../store/stateSlice";
import useThemeClass from "utils/hooks/useThemeClass";
//import CustomerEditDialog from "./CustomerEditDialog";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import useTwColorByName from "utils/hooks/useTwColorByName";
import OrganizerContext from "views/admin/OrganizerContext";
import { Dialog } from "components/ui";
import TTMap_viewer from "views/map/TTMap_viewer";
import UserEditDialog from "./UserEditDialog";
const statusColor = {
  true: "bg-emerald-500",
  false: "bg-red-500",
};

const MapView = ({ row }) => {
  const { textTheme } = useThemeClass();
  const { state, dispatch, switchMapViewerDialog, setswitchMapViewerDialog } =
    useContext(OrganizerContext);
  // const dispatch = useDispatch()

  const onEdit = () => {
    // dispatch(setDrawerOpen())
    // dispatch(setSelectedCustomer(row))
    dispatch({ type: "SET_SINGLE_USER", payload: row });
    setswitchMapViewerDialog(!switchMapViewerDialog);
    
  };

  return (
    <div
      className={`${textTheme} cursor-pointer select-none font-semibold`}
      onClick={onEdit}
    >
      Show Map
    </div>
  );
};

const ActionColumn = ({ row }) => {
  const { textTheme } = useThemeClass();
  const {
    state,
    dispatch: _dispatch,
    drawerOpen,
    setdrawerOpen,
  } = useContext(OrganizerContext);
  const dispatch = useDispatch();

  const onEdit = () => {
    setdrawerOpen(!drawerOpen);
    dispatch(setDrawerOpen());
    // dispatch(setSelectedCustomer(row))
    _dispatch({ type: "SET_SINGLE_USER", payload: row });
    // setswitchMapViewerDialog(!switchMapViewerDialog)
   
  };

  return (
    <div
      className={`${textTheme} cursor-pointer select-none font-semibold`}
      onClick={onEdit}
    >
      Edit...
    </div>
  );
};

const NameColumn = ({ row }) => {
  const { textTheme } = useThemeClass();
  const bgColor = useTwColorByName();
 
  return (
    <div className="flex items-center">
      <div>
        <Avatar
          size={40}
          shape="circle"
          //src={row.img}
          className={`${bgColor(row.fullname)} cursor-pointer`}
        >
          {acronym(row.fullname)}
        </Avatar>
      </div>
      <div className="flex flex-col ml-2 rtl:mr-2 ">
        <span className={`hover:${textTheme} font-semibold`}>
          {row.fullname}
        </span>
        <span className="text-xs">@{row.username}</span>
      </div>
    </div>
  );
};

const Mobile = ({ row }) => {
  const { textTheme } = useThemeClass();
  const bgColor = useTwColorByName();
 
  return (
    <div className="flex items-center">
      <div className="flex flex-col rtl:mr-2 ">
        <span className="text-xs">{row.mobile1}</span>
        <span className="text-xs">{row.mobile2}</span>
        <span className="text-xs">{row.mobile3}</span>
      </div>
    </div>
  );
};

const columns = [
  {
    header: "Name",
    id: "name",
    cell: (props) => {
      const row = props.row.original;
    
      return <NameColumn row={row} />;
    },
  },

  {
    header: "passwd",
    accessorKey: "passwd",
  },
  {
    header: "usertype",
    accessorKey: "usertype",
  },
  {
    header: "mobiles",
    id: "mobile",
    cell: (props) => {
      const row = props.row.original;
     
      return <Mobile row={row} />;
    },
  },
  {
    header: "address",
    accessorKey: "address",
  },

  {
    header: "date regs",
    accessorKey: "registrationdate",
  },
  //   {
  //     header: "sector",
  //     accessorKey: "sector",
  //   },
  //   {
  //     header: "address",
  //     accessorKey: "address",
  //   },

  //   {
  //     header: "Status",
  //     accessorKey: "status",
  //     cell: (props) => {
  //       const row = props.row.original;
  //       return (
  //         <div className="flex items-center">
  //           <Badge className={statusColor[row.active]} />
  //           <span className="ml-2 rtl:mr-2 capitalize">
  //             {row.active ? "Active" : "Inactive"}
  //           </span>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     header: "Date Regs",
  //     accessorKey: "registrationdate",
  //     cell: (props) => {
  //       const row = props.row.original;
  //       return <div className="flex items-center">{row.registrationdate}</div>;
  //     },
  //   },
  //   {
  //     header: "Zone",
  //     id: "action",
  //     cell: (props) => (
  //       <MapView row={props.row.original}  />
  //     ),
  //   },
  {
    header: "",
    id: "action",
    cell: (props) => <ActionColumn row={props.row.original} />,
  },
];

const UserListTable = () => {
  const dispatch = useDispatch();
  const { state, switchMapViewerDialog, setswitchMapViewerDialog, doGetUser } =
    useContext(OrganizerContext);

  const onToggleDialog = () => {
    setswitchMapViewerDialog(!switchMapViewerDialog);
  };

  //const data = useSelector((state) => state.crmCustomers.data.customerList)
  const data = state.userlist;
  

  const loading = false; //useSelector((state) => state.crmCustomers.data.loading)
  // const filterData = useSelector(
  //     (state) => state.crmCustomers.data.filterData
  // )

  //   const { pageIndex, pageSize, sort, query, total } = useSelector(
  //     (state) => state.crmCustomers.data.tableData
  //   );

  // const fetchData = useCallback(() => {
  //     dispatch(getCustomers({ pageIndex, pageSize, sort, query, filterData }))
  // }, [pageIndex, pageSize, sort, query, filterData, dispatch])

  //   useEffect(() => {
  //       fetchData()
  //   }, [fetchData, pageIndex, pageSize, sort, filterData])
  // useEffect(() => {
  //     doGetUser()
  // }, [])

  //   const tableData = useMemo(
  //     () => ({ pageIndex, pageSize, sort, query, total }),
  //     [pageIndex, pageSize, sort, query, total]
  //   );

  //   const onPaginationChange = (page) => {
  //     const newTableData = cloneDeep(tableData);
  //     newTableData.pageIndex = page;
  //     dispatch(setTableData(newTableData));
  //   };

  //   const onSelectChange = (value) => {
  //     const newTableData = cloneDeep(tableData);
  //     newTableData.pageSize = Number(value);
  //     newTableData.pageIndex = 1;
  //     dispatch(setTableData(newTableData));
  //   };

  //   const onSort = (sort) => {
  //     const newTableData = cloneDeep(tableData);
  //     newTableData.sort = sort;
  //     dispatch(setTableData(newTableData));
  //   };

  return (
    <>
      <Dialog
        isOpen={switchMapViewerDialog}
        onClose={onToggleDialog}
        width={"90vw"}
        height={"100vh"}
        style={{ paddingTop: "10px" }}
      >
        <TTMap_viewer />
      </Dialog>
      <DataTable
        columns={columns}
        data={data}
        // skeletonAvatarColumns={[0]}
        //  skeletonAvatarProps={{ width: 28, height: 28 }}
        // loading={loading}
        // pagingData={{ pageIndex, pageSize, sort, query, total }}
        // onPaginationChange={onPaginationChange}
        // onSelectChange={onSelectChange}
        //  onSort={onSort}
      />
      <UserEditDialog />
    </>
  );
};

export default UserListTable;
