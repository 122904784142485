import React, { useRef, useState, useContext } from 'react'
import { Button, Drawer } from 'components/ui'
import UserEditContent from './UserEditContent'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawerClose, setSelectedCustomer } from '../store/stateSlice'
import OrganizerContext from 'views/admin/OrganizerContext'
const DrawerFooter = ({ onSaveClick, onCancel }) => {
    return (
        <div className="text-right w-full">
            <Button size="sm" className="mr-2" onClick={onCancel}>
                Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={onSaveClick}>
                Save
            </Button>
        </div>
    )
}

const UserEditDialog = () => {
   // const [drawerOpen, setdrawerOpen] = useState(false)
   const { drawerOpen,
    setdrawerOpen, doUpdateUser} = useContext(OrganizerContext)
    const dispatch = useDispatch()
    // const drawerOpen = useSelector(
    //     (state) => state.userlist.state.drawerOpen
    // )

    const onDrawerClose = () => {
       setdrawerOpen(!drawerOpen)
        dispatch(setDrawerClose())
        dispatch(setSelectedCustomer({}))
    }

    const formikRef = useRef()

    const formSubmit = () => {
        formikRef.current?.submitForm()
    }

    return (
        <Drawer
            isOpen={drawerOpen}
            onClose={onDrawerClose}
            onRequestClose={onDrawerClose}
            closable={false}
            bodyClass="p-0"
            footer={
                <DrawerFooter
                    onCancel={onDrawerClose}
                    onSaveClick={doUpdateUser}
                    
                />
            }
        >
            <UserEditContent ref={formikRef} />
        </Drawer>
    )
}

export default UserEditDialog
