import { jwtDecode } from "jwt-decode";
import { setAuthToken } from "store/auth/sessionSlice";
import { useSelector, useDispatch } from "react-redux";

var CryptoJS = require("crypto-js");

export const sonnyencrypt = (_text, format) => {
  let text = format === "json" ? JSON.stringify(_text) : _text;
  let encrypted = CryptoJS.AES.encrypt(
    text,
    process.env.REACT_APP_CRYPTOJSKEY
  ).toString();
  return encrypted;
};
//"5915a2fe2bd77b94e5be13549657c499d6494270fad4f8da181fb4860441b4247f7724200a7924d248f70e8d99c02b4d7e5f303a6d04b853f1099c91c824ed3e"
export const sonnydecrypt = (payload) => {
  let bytes = CryptoJS.AES.decrypt(
    payload,
    "5915a2fe2bd77b94e5be13549657c499d6494270fad4f8da181fb4860441b4247f7724200a7924d248f70e8d99c02b4d7e5f303a6d04b853f1099c91c824ed3e"//process.env.REACT_APP_CRYPTOJSKEY.toString()
  );
  let originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;
};

export const isTokenExpired = async (token) => {
  let decoded = sonnydecrypt(token);
  let decodedToken = jwtDecode(decoded);
  const expiry = decodedToken.exp;
  let expired = Math.floor(new Date().getTime() / 1000) >= expiry;
 
  return expired;
};
