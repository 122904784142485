import React, { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomerList, putCustomer } from '../store/dataSlice'
import { setDrawerClose } from '../store/stateSlice'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
//import CustomerForm from 'views/members/MembershipForm'
import UserEditForm from './UserEditForm'
import dayjs from 'dayjs'

const UserEditContent = forwardRef((_, ref) => {
    const dispatch = useDispatch()

    // const customer = useSelector(
    //     (state) => state.crmCustomers.state.selectedCustomer
    // )
    // const data = useSelector((state) => state.crmCustomers.data.customerList)
    // const { id } = customer

    // const onFormSubmit = (values) => {
    //     const {
    //         name,
    //         birthday,
    //         email,
    //         img,
    //         location,
    //         title,
    //         phoneNumber,
    //         facebook,
    //         twitter,
    //         pinterest,
    //         linkedIn,
    //     } = values

    //     const basicInfo = { name, email, img }
    //     const personalInfo = {
    //         location,
    //         title,
    //         birthday: dayjs(birthday).format('DD/MM/YYYY'),
    //         phoneNumber,
    //         facebook,
    //         twitter,
    //         pinterest,
    //         linkedIn,
    //     }
    //     let newData = cloneDeep(data)
    //     let editedCustomer = {}
    //     newData = newData.map((elm) => {
    //         if (elm.id === id) {
    //             elm = { ...elm, ...basicInfo }
    //             elm.personalInfo = { ...elm.personalInfo, ...personalInfo }
    //             editedCustomer = elm
    //         }
    //         return elm
    //     })
    //     if (!isEmpty(editedCustomer)) {
    
    //         dispatch(putCustomer(editedCustomer))
    //     }
    //     dispatch(setDrawerClose())
    //     dispatch(setCustomerList(newData))
    // }

    return (
        <UserEditForm
            ref={ref}
       //     onFormSubmit={onFormSubmit}
          //  customer={customer}
        />
    )
})

export default UserEditContent
