import axios from "axios";
import { sonnydecrypt, isTokenExpired } from "commonhelper";

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const HTTP_STATUS = {
  OK: 200,
  UNAUTHORIZED: 401,
  SERVER_ERROR: 500,
  NOT_FOUND: 404,
  CONFLICT: 409,
};

export const API_URL = {
  API_LIVE: "https://svc-pigeontik.com/live", //"http://localhost:5020",//
  API_CLUB: "https://svc-pigeontik.com/clb", //"http://localhost:5020", //
  API_REGISTRATION: "https://svc-pigeontik.com/regs", //"http://localhost:5000",//
  API_RACE_EVENT: "https://svc-pigeontik.com/evt", //"http://localhost:5010", //
  API_MEMBERSHIP: "https://svc-pigeontik.com/mem", //"http://localhost:5030", // 
  API_BONDING:  "https://svc-pigeontik.com/bnd",//"http://localhost:5040", //"
  API_WALLET: "https://svc-pigeontik.com/wall", //"http://89.116.230.33:5050"
};

const useApi = (baseURL) => {
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 300000,
  });



  const sendRequest = async ({
    _baseUrl,
    url,
    method,
    data,
    headers,
    responseType,
    contentType,
    
  }) => {
    let response = {};
  
    try {
      response = await instance({
        baseURL: _baseUrl || baseURL,
        url: url,
        method: method,
        data: data,
        headers: headers,
        responseType: responseType,
      });
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 401 && data.Message === "Unauthorized APIKey") {
          response = error.response;
        } else response = error.response;
      }
    }
  

    return response;
  };

  return { sendRequest };
};

export default useApi;
