import BaseService from './BaseService'

export const API_URL = {
    API_CLUB : "http://localhost:5020",
    API_REGISTRATION : "http://89.116.230.33:5000", 
    API_RACE_EVENT : "http://89.116.230.33:5010", 
    API_MEMBERSHIP : "http://89.116.230.33:5030", 
    API_BONDING : "http://89.116.230.33:5040", 
    API_WALLET : "http://89.116.230.33:5050"  
}

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    resolve(response)
                })
                .catch((errors) => {
                    reject(errors)
                })
        })
    },
}

export default ApiService
