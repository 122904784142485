import React, { useContext, useState, useEffect } from "react";
import {
  DatePicker,
  Input,
  FormItem,
  Avatar,
  Upload,
  FormContainer,
} from "components/ui";
import {
  HiUserCircle,
  HiMail,
  HiLocationMarker,
  HiPhone,
  HiCake,
  HiOutlineUser,
} from "react-icons/hi";
import { Field } from "formik";
import OrganizerContext from "views/admin/OrganizerContext";
const UserEditForm = (props) => {
  const { state, doUpdateForm, formEditUser, setformEditUser } =
    useContext(OrganizerContext);

  //const [formData, setFormData] = useState(state.singleuser);

  const { touched, errors } = props;
 
  const onSetFormFile = (form, field, file) => {
    form.setFieldValue(field.name, URL.createObjectURL(file[0]));
  };

  const onFormChanged = (e) => {
    setformEditUser((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    setformEditUser(state.singleuser);
  }, []);

  return (
    <>
      <div>{JSON.stringify(formEditUser)}</div>

      <div className="ml-4 mr-4 mt-8">
        <FormContainer>
          <FormItem label="userid">
            <Input
              type="text"
              autoComplete="off"
              name="userid"
              placeholder="userid"
              component={Input}
              value={formEditUser.userid}
              disabled
              // prefix={<HiUserCircle className="text-xl" />}
            />
          </FormItem>
          <FormItem label="Name">
            <Input
              type="text"
              autoComplete="off"
              name="fullname"
              placeholder="fullname"
              component={Input}
              value={formEditUser.fullname}
              onChange={onFormChanged}
              // prefix={<HiMail className="text-xl" />}
            />
          </FormItem>
          <FormItem label="Username">
            <Input
              type="text"
              autoComplete="off"
              name="username"
              placeholder="username"
              component={Input}
              value={formEditUser.username}
              onChange={onFormChanged}
              // prefix={<HiLocationMarker className="text-xl" />}
            />
          </FormItem>
          <FormItem label="Passwd">
            <Input
              type="text"
              autoComplete="off"
              name="passwd"
              placeholder="passwd"
              component={Input}
              value={formEditUser.passwd}
              onChange={onFormChanged}
              // prefix={<HiPhone className="text-xl" />}
            />
          </FormItem>
          <FormItem label="User Type">
            <Input
              type="text"
              autoComplete="off"
              name="usertype"
              placeholder="usertype"
              component={Input}
              value={formEditUser.usertype}
              onChange={onFormChanged}
              //  prefix={<HiPhone className="text-xl" />}
            />
          </FormItem>
       
        </FormContainer>
      </div>
    </>
  );
};

export default UserEditForm;
